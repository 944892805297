<script lang="ts" setup>
const { t } = useT();
const { open } = useAppModals();
const isGuest = useIsGuest();

const {
	feedback,
	isFormSent,
	subject,
	feedbackMsg,
	maxLength,
	minLength,
	isSubjectTouched,
	isFeedbackMsgTouched,
	isFeedbackMsgValid,
	isLimitedValid,
	verifyLoading,
	categories,
	dataSettings,
	isSubjectShowError,
	isFeedbackMsgShowError,
	numberSymbols,
	handleSendFeedbackClick
} = useFeedbackForm();
const { handleSupportButtonClick } = useSupportChat();

const handleClick = () => {
	if (isGuest.value) {
		open("LazyOModalSignup");
		return;
	}

	handleSendFeedbackClick();
};
</script>

<template>
	<AAnimationSlide
		v-if="dataSettings?.statusCode !== 403"
		mode="left-to-right"
		iterationCount="3"
		:duration="0.2"
		:distance="5"
		:active="!isLimitedValid"
		:class="['feedback', { 'feedback-guest': isGuest }]"
	>
		<div ref="feedback" class="head">
			<AText v-if="dataSettings?.data?.title" class="text-cannes" :modifiers="['uppercase', 'medium', 'center']">
				<NuxtImg
					class="icon"
					src="/nuxt-img/footer/feedback.png"
					alt="feedback"
					width="16"
					height="16"
					format="webp"
					loading="lazy"
				/>
				{{ dataSettings?.data?.title }}
			</AText>

			<AText v-if="isLimitedValid" class="text-cocoa" :modifiers="['center']">
				{{ dataSettings?.data?.description }}
			</AText>
			<AText v-else class="invalid" :modifiers="['center']">
				<i18n-t keypath="The response limit has been exceeded, please try tomorrow or contact {key}" tag="div">
					<template #key>
						<AText :modifiers="['link', 'underline']" class="link" @click="handleSupportButtonClick">
							{{ t("our support team.") }}
						</AText>
					</template>
				</i18n-t>
			</AText>
		</div>
		<div v-if="isFormSent" class="form-sent">
			<NuxtImg
				class="icon"
				src="/nuxt-img/footer/feedback-success.png"
				alt="feedback-success-sent"
				width="48"
				height="48"
				format="webp"
				loading="lazy"
			/>
			<AText variant="toledo" :modifiers="['bold']">{{ t("Feedback sent") }}</AText>
			<AText class="text-cocoa" :modifiers="['center']">{{
				t("Thank you for helping us become better. Take care and game on with FortuneWheelz")
			}}</AText>
		</div>
		<form v-else class="form" @submit.prevent="handleClick">
			<div v-if="categories?.length" class="row-select">
				<MDropdown
					v-model="subject"
					:placeholder="t('Select a feedback subject')"
					:class="['select', { invalid: isSubjectShowError }]"
					savePlaceholder
					@blur="isSubjectTouched = true"
				>
					<template #beforeInput>
						<ASvg v-if="subject" name="20/feedback2" class="icon" filled />
					</template>
					<template #content="{ setValue }">
						<AOption v-for="(item, index) in categories" :key="index" @click="setValue(item.name)">
							{{ item?.name }}
						</AOption>
					</template>
				</MDropdown>
				<AAnimationSlide :active="!!isSubjectShowError" :class="{ hide: !isSubjectShowError }">
					<AText variant="topeka" class="invalid">{{ t("The feedback subject is not selected") }}</AText>
				</AAnimationSlide>
			</div>

			<label class="row-textarea">
				<div class="relative">
					<MTextarea
						v-model="feedbackMsg"
						:placeholder="t('Enter the text (min') + ` ${minLength} ` + t('characters)')"
						autocomplete="feedbackMsg"
						:maxlength="maxLength"
						clear
						:invalid="isFeedbackMsgShowError"
						class="message"
						@blur="isFeedbackMsgTouched = true"
					/>
					<AText variant="tivat" class="number-synbol" :data-txt="numberSymbols" />
				</div>
				<AAnimationSlide :active="!!isFeedbackMsgShowError" :class="{ hide: !isFeedbackMsgShowError }">
					<AText variant="topeka" class="invalid">
						<i18n-t keypath="Enter the text (min {key} characters)">
							<template #key>
								{{ minLength }}
							</template>
						</i18n-t>
					</AText>
				</AAnimationSlide>
			</label>
			<AButton
				type="submit"
				class="button"
				variant="primary"
				:modifiers="[!isFeedbackMsgValid && !isGuest ? 'disabled' : '']"
			>
				<AAnimationSpin v-if="verifyLoading">
					<ASvg class="preloader-icon" name="24/loader" filled />
				</AAnimationSpin>
				<AText v-else variant="turin" :modifiers="['medium']">
					{{ isGuest ? t("Sign Up or Login") : t("Send message") }}
				</AText>
			</AButton>
		</form>
	</AAnimationSlide>
</template>

<style scoped lang="scss">
.feedback {
	display: flex;
	width: 328px;
	background: var(--chandigarh);
	padding: gutter(2);
	flex-direction: column;
	border-radius: 8px;
	gap: gutter(2);
	margin-bottom: gutter(3);

	@include media-breakpoint-down(lg) {
		width: 100%;
		max-width: 500px;

		@media screen and (orientation: landscape) {
			margin: 0 auto gutter(3);
		}
	}

	.form-sent {
		gap: 16px;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}

	.head {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: gutter(0.5);

		.icon {
			display: inline-flex;
			margin: gutter(-0.25) gutter(0.25) 0 0;
			transform: translateY(2px);
		}
	}

	.invalid {
		color: var(--copenhagen);
	}

	.hide {
		visibility: hidden;
	}

	.form {
		display: flex;
		flex-direction: column;
		gap: gutter(0.5);

		.label {
			margin-bottom: gutter(0.5);
		}

		.message {
			height: 111px;
			padding: gutter(1.25);
			@include scrollbar(4px, 4px, transparent, var(--caracas), transparent);
		}

		.button {
			margin-top: gutter(0.25);
			height: 50px;

			.preloader-icon {
				font-size: 24px;
			}
		}
	}
}

.row-select:deep(.select) {
	display: flex;

	& > div {
		width: 100%;
		margin: 0px !important;
		border: none !important;
	}

	.dropdown {
		box-shadow: none;
		background: var(--craiova);

		input {
			color: var(--cannes);
			font-weight: 600;
			height: 50px;
		}

		.placeholder {
			display: flex;
			align-items: center;
			height: 50px;
		}
	}

	&.invalid .dropdown {
		box-shadow: 0 0 1px 1px var(--changzhi);
	}

	.popper,
	.option {
		border: none;
		background: var(--craiova);
		color: var(--cannes);
		font-weight: 400;
	}

	.popper {
		box-shadow: 0 3px 3px var(--chandigarh);
		padding: gutter(0.5) 0;
		@include scrollbar(4px, 4px, transparent, var(--cocaracascoi), transparent);
	}
	.option:hover {
		background: var(--chandigarh);
		border-radius: 0;
	}

	.placeholder {
		color: var(--cocoa);
		padding-left: 0;

		&.moved {
			padding-left: gutter(1.25);
			transform: translate(16px, -10px);
		}
	}
}

.feedback-guest {
	.row-select,
	.row-textarea {
		pointer-events: none;
		opacity: 0.4;
	}
}

.relative {
	position: relative;
}

.number-synbol {
	color: var(--cocoa);
	position: absolute;
	bottom: -6px;
	left: 10px;
	padding: 0 gutter(0.75);

	&:before {
		content: "";
		display: block;
		height: 2px;
		width: 100%;
		background: var(--a-textarea-background);
		position: absolute;
		left: 0;
		bottom: 6px;
	}

	&:after {
		content: attr(data-txt);
		position: relative;
		z-index: 1;
	}
}
</style>
